
import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [

      // DASHBOARD 
        {
          path: '/',
          name: 'Dashboard',
          component: () => import('./views/pages/Dashboard.vue'),
          meta: { 
            auth: true 
          }
        },
      //

      // USER
        {
          path: '/user/setting',
          name: 'user-setting',
          component: () => import('@/views/user/setting/UserSetting.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'User Setting', active: true }
            ],
            pageTitle: 'Setting',
            auth: true
          }
        },

        {
          path: '/users',
          name: 'users',
          component: () => import('@/views/user/UsersList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Users', active: true }
            ],
            pageTitle: 'Users',
            auth: true,
            role: ['1'],
            //level: ['1'],
          }
        },
      //

      // CONFIG
        {
          path: '/config/menus/:filter',
          name: 'menus',
          component: () => import('@/views/config/menus/Menu.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Menu', active: true }
            ],
            pageTitle: 'Menu',
            auth: true,
            role: ['1'],
            //level: ['1'],
          }
        },

        {
          path: '/config/menu',
          name: 'menu',
          component: () => import('@/views/config/menu/MenuList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Menu', active: true }
            ],
            pageTitle: 'Menu',
            auth: true,
            role: ['1'],
            //level: ['1'],
          }
        },

        {
          path: '/config/item_menu/:slug_nav_menu',
          name: 'item_menu',
          component: () => import('@/views/config/menu/ItemMenuList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Navigation Menu', url: '/config/nav_menu' },
              { title: 'Item Menu', active: true }
            ],
            pageTitle: 'Item Menu',
            parent: 'item_menu',
            auth: true,
            role: ['1'],
            //level: ['1'],
          }
        },

        {
          path: '/config/nav_menu',
          name: 'nav_menu',
          component: () => import('@/views/config/menu/NavMenuList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Navigation Menu', active: true }
            ],
            pageTitle: 'Navigation Menu',
            auth: true,
            role: ['1'],
            //level: ['1'],
          }
        },
  
        {
          path: '/config/roles',
          name: 'roles',
          component: () => import('@/views/config/role/RoleList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Roles', active: true }
            ],
            pageTitle: 'Roles',
            auth: true,
            role: ['1'],
            //level: ['1'],
          }
        },

        {
          path: '/config/role/menu/:slug',
          name: 'role-menu',
          component: () => import('@/views/config/role/RoleMenuList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Role Menu', active: true }
            ],
            pageTitle: 'Role Menu',
            auth: true,
            role: ['1'],
            //level: ['1'],
          }
        },
      
        {
          path: '/config/options',
          name: 'options',
          component: () => import('@/views/config/options/OptionsList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Options', active: true }
            ],
            pageTitle: 'Options',
            auth: true,
            role: ['1'],
            //level: ['1'],
          }
        },
      //

      // MASTER
      
        {
          path: '/master/lampiran',
          name: 'lampiran',
          component: () => import('@/views/master/lampiran/LampiranList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Lampiran', active: true }
            ],
            pageTitle: 'Lampiran',
            auth: true,
            role: ['1'],
            //level: ['1'],
          }
        },
      
        {
          path: '/master/kecamatan',
          name: 'kecamatan',
          component: () => import('@/views/master/kecamatan/KecamatanList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Kecamatan', active: true }
            ],
            pageTitle: 'Kecamatan',
            auth: true,
            role: ['1'],
            //level: ['1'],
          }
        },
      
        {
          path: '/master/kelurahan',
          name: 'kelurahan',
          component: () => import('@/views/master/kelurahan/KelurahanList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Kelurahan', active: true }
            ],
            pageTitle: 'Kelurahan',
            auth: true,
            role: ['1'],
            //level: ['1'],
          }
        },
      
        {
          path: '/master/laporan',
          name: 'laporan',
          component: () => import('@/views/master/laporan/LaporanList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Laporan', active: true }
            ],
            pageTitle: 'Laporan',
            auth: true,
            role: ['1'],
            //level: ['1'],
          }
        },
      
        {
          path: '/master/kekerasan_bentuk',
          name: 'kekerasan_bentuk',
          component: () => import('@/views/master/kekerasanbentuk/KekerasanBentukList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Bentuk Kekerasan', active: true }
            ],
            pageTitle: 'Bentuk Kekerasan',
            auth: true,
            role: ['1'],
            //level: ['1'],
          }
        },
      
        {
          path: '/master/kekerasan_modus',
          name: 'kekerasan_modus',
          component: () => import('@/views/master/kekerasanmodus/KekerasanModusList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Modus Kekerasan', active: true }
            ],
            pageTitle: 'Modus Kekerasan',
            auth: true,
            role: ['1'],
            //level: ['1'],
          }
        },
      
        {
          path: '/master/kejadian_tempat',
          name: 'kejadian_tempat',
          component: () => import('@/views/master/kejadiantempat/KejadianTempatList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Tempat Kejadian', active: true }
            ],
            pageTitle: 'Tempat Kejadian',
            auth: true,
            role: ['1'],
            //level: ['1'],
          }
        },
      
        {
          path: '/master/hubungan_korban',
          name: 'hubungan_korban',
          component: () => import('@/views/master/hubungankorban/HubunganKorbanList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Hubungan Korban', active: true }
            ],
            pageTitle: 'Hubungan Korban',
            auth: true,
            role: ['1'],
            //level: ['1'],
          }
        },
      
        {
          path: '/master/tempat_lapor',
          name: 'tempat_lapor',
          component: () => import('@/views/master/tempatlapor/TempatLaporList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Tempat Lapor', active: true }
            ],
            pageTitle: 'Tempat Lapor',
            auth: true,
            role: ['1'],
            //level: ['1'],
          }
        },
      
        {
          path: '/master/layanan_dibutuhkan',
          name: 'layanan_dibutuhkan',
          component: () => import('@/views/master/layanandibutuhkan/LayananDibutuhkanList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Layanan Dibutuhkan', active: true }
            ],
            pageTitle: 'Layanan Dibutuhkan',
            auth: true,
            role: ['1'],
            //level: ['1'],
          }
        },
      
        {
          path: '/master/agama',
          name: 'agama',
          component: () => import('@/views/master/agama/AgamaList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Agama', active: true }
            ],
            pageTitle: 'Agama',
            auth: true,
            role: ['1'],
            //level: ['1'],
          }
        },

      // CHAT
        
      {
        path: '/chat',
        name: 'chat',
        component: () => import('@/views/chat/Chat.vue'),
        meta: {
          auth: true,
          role: ['1'],
          //level: ['1'],
        }
      },

      // TASKS

        {
          path: '/laporan/:filter_type/:filter',
          name: 'laporan-list',
          component: () => import('@/views/laporan/Laporan.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Laporan', active: true }
            ],
            pageTitle: 'Laporan',
            auth: true,
            role: ['1'],
            //level: ['1'],
          }
        },
      
      // POST
      
        {
          path: '/post/:filter_type/:filter',
          name: 'post',
          component: () => import('@/views/post/Post.vue'),
          meta: {
            pageTitle: 'Post',
            auth: true,
            role: '1',
            //level: ['1'],
          }
        },

      // CHAT
        
        {
          path: '/chat/:type',
          name: 'chat',
          component: () => import('./views/chat/Chat.vue'),
          meta: {
            auth: true,
            role: '1',
            no_scroll: true
          }
        }
        
        
      ]
    },

    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import('@/views/pages/Login.vue'),
        },
        {
          path: '/pages/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/pages/NotAuthorized.vue'),
          meta: {
            auth: true,
          }
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue')
        },
        {
          path: '/clear-data',
          name: 'clear-data',
          component: () => import('@/views/pages/ClearData.vue'),
          meta: {
            auth: true,
          }
        }
      ]
    },

    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    if(store.getters['auth/guest']){
      next({ name: 'login' })
    }
    else{
      next()
    } 
  } else {
    next()
  }

  if (to.matched.some(record => record.meta.role)) {
    //if (store.getters['auth/otoritas'] === to.meta.role) {
    if (to.meta.role.includes(store.getters['auth/otoritas']) === true) {
      next()
    } else {
      next({ name: 'page-not-authorized' })
    }
  } else {
    next()
  }

/*
  if (to.matched.some(record => record.meta.level)) {
    //let level_user = ;
    if (to.meta.level.includes(store.getters['auth/level']) === true) {
      next()
    } else {
      next({ name: 'page-not-authorized' })
    }
  } else {
    next()
  }
*/

})

router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }
})

export default router
