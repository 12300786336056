// permission
import Vue from 'vue'
import store from './store'

Vue.directive('can', {
  bind: function (el, binding, vnode) {
    if (! store.getters['auth/permission']
        .includes(binding.expression
          .replace(/'/g, "")
            .replace(/"/g, ""))) {
       vnode.elm.style.display = "none";
    }
  }
})

Vue.directive('dis', {
  bind: function (el, binding, vnode) {
    if (!store.getters['auth/permission']
        .includes(binding.expression
          .replace(/'/g, "")
            .replace(/"/g, ""))) {
        vnode.elm.setAttribute('style', 'cursor: not-allowed; pointer-events: none; opacity: 0.4;');
        //vnode.elm.setAttribute('disabled');
    }
  }
})
