export default {
    namespaced: true,
    state: {
        menu    : {},
    },
    mutations: {
        set: (state, payload) => {
            state.menu  = payload
        },
    },
    actions: {
        set: ({commit}, payload) => {
            commit('set', payload)
        },
    },
    getters: {
        menu  : state => state.menu,
    }
}