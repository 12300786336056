"use strict"
import Vue from 'vue'
const Helper = {
    install(Vue) {
        Vue.prototype.appName = process.env.VUE_APP_NAME
        Vue.prototype.getImage = function (image){
            if(image!=null && image.length>0 && image!=undefined){
                return process.env.VUE_APP_BACKEND_URL + "/images"+ image
            }
            return "unavailable.png"
        }
        Vue.prototype.getURL = function (url){
            if(url!=null && url.length>0 && url!=undefined){
                return process.env.VUE_APP_BACKEND_URL + url
            }
        }
        Vue.prototype.getPDF = function (pdf){
            if(pdf!=null && pdf.length>0 && pdf!=undefined){
                return process.env.VUE_APP_BACKEND_URL + "/file/"+ pdf
            }
            return "unavailable.png"
        }
    }
}

Vue.use(Helper)